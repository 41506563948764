import React, { Component } from 'react'
import styles from './Item.module.css'

export default class Item extends Component {
  render() {
    return (
      <div className={styles.item}>
        <div className={styles.header}>
          <div className={styles.employeeOrigin}>{this.props.employeeOrigin}</div>
          <div className={styles.date}>{this.props.date}</div>
        </div>
        <div className={styles.body}>
          <div className={styles.workOrderNumber}>{this.props.workOrderNumber}</div>
          <div className={styles.customerName}>{this.props.customerName}</div>
        </div>
      </div>
    )
  }
}