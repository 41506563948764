import React, {Component} from 'react';
import '../App.css';
import Menu from './Menu'
import Dashboard from './Dashboard'
import axios from 'axios'

export default class Home extends Component {
  state ={
    showMenu: false,
    sortBy: 'descending',
    searchInput: '',
    entries: {sample: 1, sample2: 2},
    filteredEntries: {},
    workOrderFetchInProgress: false,
    workOrderFetchComplete: false,
    workOrderFetchError: false,
    authFormFetchInProgress: false,
    authFormFetchComplete: false,
    authFormFetchError: false,
    isAuthorized: true
  }

componentDidMount = () => {
  this.fetchAuthForms()
  this.fetchWorkOrders()
}

fetchWorkOrders = () => {
  this.setState({workOrderFetchInProgress: true}, async () => {
    try {
      const response = await axios.get('https://mse-counter.firebaseio.com/log.json')
      const filteredEntries = Object.keys(response.data).map((id) => response.data[id])
      this.setState({entries: response.data, filteredEntries: filteredEntries, workOrderFetchComplete: true, workOrderFetchInProgress: false}, () => {
      }) 
    } catch (e) {
      this.setState({workOrderFetchError: true})
    }
  })
}

fetchAuthForms = () => {
  this.setState({authFormFetchInProgress: true}, async () => {
    try {
      const response = await axios.get('https://mse-counter.firebaseio.com/inverter_repair_auth_form.json')
      const filteredAuthForms = Object.keys(response.data).map((id) => response.data[id])
      this.setState({authForms: response.data, filteredAuthForms: filteredAuthForms, authFormFetchComplete: true, authFormFetchInProgress: false}, () => {
      }) 
    } catch (e) {
      this.setState({authFormFetchError: true})
    }
  })
}

toggleMenu = action => {
  this.setState({showMenu: action})
}

toggleSorting = (type) => {
  this.setState({sortBy: type})
}

updatePassword = e => {
  this.setState({passwordInput: e.target.value})
}

updateInput = value => {
  this.setState({searchInput: value}, () => {
    let filteredEntries = {}
    Object.keys(this.state.entries).forEach((id, i) => {
      const item = this.state.entries[id]
      const customer_name_match = item.customer_name.toLowerCase().includes(value.toLowerCase())
      const work_order_match = item.work_order.toLowerCase().includes(value.toLowerCase())
      if (customer_name_match || work_order_match) {
        filteredEntries[id] = item
      }
    })
    const newFilteredEntries = Object.keys(filteredEntries).map((id) => filteredEntries[id])
      this.setState({filteredEntries: newFilteredEntries})
    })
  }

  render() {
    const app = <Dashboard 
      sortBy={this.state.sortBy} 
      toggleMenu={this.toggleMenu} 
      updateInput={this.updateInput}
      clearInput={this.clearInput}
      entries={this.state.filteredEntries} 
      searchInput={this.state.searchInput}
      /> 
    const menu = this.state.showMenu ? <Menu sortBy={this.state.sortBy} toggleSorting={this.toggleSorting} toggleMenu={this.toggleMenu} /> : null
    return (
      <div className="App">
        {app}
        {menu}
      </div>
    )
  }
}
