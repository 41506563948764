import React, { Component } from 'react'

export default class ListBy extends Component {
  render() {
    const enabledStyle = {
      backgroundColor: '#afa',
      color: '#000',
      padding: '0.5em',
      flex: 1,
      margin: '1em',
      cursor: 'pointer'
    }
    const disabledStyle = {
      backgroundColor: '#fff',
      color: '#000',
      padding: '0.5em',
      flex: 1,
      margin: '1em',
      cursor: 'pointer'
      
    }
    return (
      <div>
        <p style={{color: '#fff', textAlign: 'left', marginLeft: '12.5%', fontSize: '1.5em' }}>List By:</p>
          <div style={{backgroundColor: 'rgba(255,255,255,0.15)', display: 'flex', width: '75%', margin: '0 auto'}}>
            <div onClick={() => this.props.toggleSorting('descending')} style={this.props.sortBy==='descending' ? enabledStyle : disabledStyle}>Newest</div>
            <div onClick={() => this.props.toggleSorting('ascending')} style={this.props.sortBy==='descending' ? disabledStyle : enabledStyle}>Oldest</div>
          </div>
      </div>
    )
  }
}
