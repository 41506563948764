import React, { Component } from 'react'
import Header from '../components/Dashboard/Header'
import Item from '../components/Dashboard/Item'
import styles from './Dashboard.module.css'
// import search from '../assets/icons/search.svg'
import { DateTime } from 'luxon';
import ReactList from 'react-list';

export default class Dashboard extends Component {

  render() {
    
    const mseEmployees = {
      '+18582313385': 'David J.',
      '+18582313386': 'Mark S.',
      '+16199978750': 'Mary A.',
      '+16193189972': 'Kim A.',
      // '+18582312608': 'Joan T.', // Now an electrician
      '+18583758934': 'Joan T.',
      '+16028820389': 'Bill D.',
      '+18582312735': 'Andrea S.',
      '+18582313397': 'Stuart R.',
      // '+16194007723': 'Holly V.',
      // '+18582316132': 'Ruby T.'
    }

    const sortedItemKeys = this.props.sortBy === 'descending' 
      ? Object.keys(this.props.entries).reverse()
      : Object.keys(this.props.entries)

    const dataItems = sortedItemKeys.map(id => this.props.entries[id])

    const searchBlock = <div className={styles.searchContainer}>
      <input 
        value={this.props.searchInput} 
        className={styles.searchInput} 
        onChange={e => this.props.updateInput(e.target.value)}
        placeholder='Enter a work order # or keyword'
        type='text' 
        />
        {this.props.searchInput.length>0 && <div className={styles.clearSearch} onClick={() => this.props.updateInput('')}>x</div>}
      {/* <img alt='search' style={{position: 'absolute', left: '3.5em', top: '1.25em'}} src={search} /> */}
    </div>

  const renderItem = (index, key) => {
    const item = dataItems[index]
    const name = mseEmployees[item.requester] ? mseEmployees[item.requester] : 'Other'
    const date_and_time = item.created_at.replace(' ', 'T').trim()
    var d1 = DateTime.fromISO(date_and_time).minus({hours: 8})
    const date = new Date(d1.ts).toLocaleString("en-US", {timeZone: "America/Los_Angeles"})
    return <Item 
      key={key}
      employeeOrigin={name}
      date={date}
      workOrderNumber={item.work_order}
      customerName={item.customer_name}
      />
    }

    return (
      <div>
        <Header onClick={() => this.props.toggleMenu(true)} />
        {searchBlock}
        <ReactList 
          itemRenderer={renderItem} 
          length={dataItems.length}
          />
      </div>
    )
  }
}
