exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".AuthForms_section__3fG91 {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  flex: 1 1;\n  border-bottom: 1px solid #eee;\n}\n\n.AuthForms_title__1z5hT {\n  font-weight: bold;\n  width: 12em;\n  text-align: right;\n  margin-right: 1em;\n}\n\n.AuthForms_sectionBody__3_ihl {\n  border-collapse: collapse;\n  display: flex;\n  flex-direction: column;\n  padding: 0.5em 0;\n  flex-wrap: wrap;\n  margin-left: 1em;\n  flex: 1 1;\n  text-align: left;\n  white-space: wrap;\n}\n\n.AuthForms_symptom__3kF0i {\n  padding-right: 1em;\n}\n\n.AuthForms_authForm__GutOF {\n  display: flex;\n  flex-direction: column;\n  width: 90vw;\n  min-width: 30em;\n}\n\n.AuthForms_authFormContainer__1KAHW {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-width: 100vw;\n  font-size: 0.75em;\n  flex-wrap: wrap;\n\n}\n\n.AuthForms_address__2pO1T {\n  display: flex;\n  flex-direction: column;\n}\n\n.AuthForms_itemHeader__2HQ4k {\n  width: calc(100%);\n  background-color: #eee;\n  padding: 1em 0;\n  margin: 1em 0;\n}", ""]);

// Exports
exports.locals = {
	"section": "AuthForms_section__3fG91",
	"title": "AuthForms_title__1z5hT",
	"sectionBody": "AuthForms_sectionBody__3_ihl",
	"symptom": "AuthForms_symptom__3kF0i",
	"authForm": "AuthForms_authForm__GutOF",
	"authFormContainer": "AuthForms_authFormContainer__1KAHW",
	"address": "AuthForms_address__2pO1T",
	"itemHeader": "AuthForms_itemHeader__2HQ4k"
};