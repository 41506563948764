import React from 'react'
import styles from './Header.module.css'
import menu from '../../assets/icons/menu.svg'
import mseLogo from '../../assets/icons/mse-logo.png'
import { Link } from 'react-router-dom'
export default function Header(props) {
  
    return (
      <div className={styles.header}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <img alt='logo' className={styles.logo} src={mseLogo} />
          <Link className={styles.link} to='/'>Work Orders</Link>
          <Link className={styles.link} to='/auth_forms'>Repair Forms</Link>
        </div>
        <img alt='menu' onClick={props.onClick} className={styles.menuButton} src={menu} />
      </div>
  )
}
