import React, {Component} from 'react';
import Menu from '../Menu'
import axios from 'axios'
import styles from './AuthForms.module.css'
// import Header from '../../components/Dashboard/Header'
import uuidv4 from 'uuid/v4'
import {DateTime} from 'luxon'
// import ListBy from '../../components/Menu/ListBy';
import Header from '../../components/Dashboard/Header'

export default class Home extends Component {
  state ={
    showMenu: false,
    searchInput: '',
    entries: [],
    filteredEntries: {},
    authFormFetchInProgress: false,
    authFormFetchComplete: false,
    authFormFetchError: false,
    isAuthorized: true,
    sortBy: 'descending',
  }

componentDidMount = () => {
  this.fetchAuthForms()
}

toggleMenu = action => {
  this.setState({showMenu: action})
}


toggleSorting = (type) => {
  this.setState({sortBy: type})
}

fetchAuthForms = () => {
  this.setState({authFormFetchInProgress: true}, async () => {
    try {
      const response = await axios.get('https://mse-counter.firebaseio.com/inverter_repair_auth_form.json')
      const filteredAuthForms = Object.keys(response.data).map((id) => response.data[id])
      this.setState({entries: filteredAuthForms, authFormFetchComplete: true, authFormFetchInProgress: false}, () => {
      }) 
    } catch (e) {
      this.setState({authFormFetchError: true})
    }
  })
}

  // updateInput = e => {
  //   const { value } = e.target
  //   this.setState({searchInput: value}, () => {
  //     let filteredEntries = {}
  //     Object.keys(this.state.entries).forEach((id, i) => {
  //       const item = this.state.entries[id]
  //       const customer_name_match = item.customer_name.toLowerCase().includes(value.toLowerCase())
  //       const work_order_match = item.work_order.toLowerCase().includes(value.toLowerCase())
  //       if (customer_name_match || work_order_match) {
  //         filteredEntries[id] = item
  //       }
  //     })
  //     const newFilteredEntries = Object.keys(filteredEntries).map((id) => filteredEntries[id])
  //     this.setState({filteredEntries: newFilteredEntries})
  //   })
  // }

  render() {
    // const sortedEntries = this.state.sortBy === 'descending' 
    // ? this.state.entries.reverse()
    // : this.state.entries

    // const sortedItemKeys = this.props.sortBy === 'descending' 
    // ? Object.keys(this.props.entries).reverse()
    // : Object.keys(this.props.entries)

    const forms = this.state.entries.map(item => {
      const {
        entry_date,
        billing_address_city,
        billing_address_country,
        billing_address_line_1,
        billing_address_line_2,
        billing_address_state,
        billing_address_zip,
        cabinet_rusted,
        company_name,
        date_of_manufacture,
        does_not_charge,
        does_not_invert,
        does_not_turn_on,
        email,
        excessive_dirt,
        fans_not_running,
        fault_lights_on,
        flashing_leds,
        has_burned_odor,
        inverter_manufacturer,
        model,
        name_first,
        name_last,
        other_symptoms,
        output_voltage_low,
        phone,
        phone_other,
        power_surges,
        repair_location,
        rodent_activity,
        serial,
        shipping_address_city,
        shipping_address_country,
        shipping_address_line_1,
        shipping_address_line_2,
        shipping_address_name,
        shipping_address_state,
        shipping_address_zip,
        shipping_same_as_billing,
        wires_are_melted
        } = item


        const symptoms = <div className={styles.section}>
          <div className={styles.title}>Symptoms</div>
          <div className={styles.sectionBody} style={{display: 'flex'}}>
            {cabinet_rusted && <div className={styles.symptom}>cabinet_rusted</div>}
            {does_not_charge && <div className={styles.symptom}>does_not_charge</div>}
            {does_not_invert && <div className={styles.symptom}>does_not_invert</div>}
            {does_not_turn_on && <div className={styles.symptom}>does_not_turn_on</div>}
            {excessive_dirt && <div className={styles.symptom}>excessive_dirt</div>}
            {fans_not_running && <div className={styles.symptom}>fans_not_running</div>}
            {fault_lights_on && <div className={styles.symptom}>fault_lights_on</div>}
            {flashing_leds && <div className={styles.symptom}>flashing_leds</div>}
            {has_burned_odor && <div className={styles.symptom}>has_burned_odor</div>}
            {output_voltage_low && <div className={styles.symptom}>output_voltage_low</div>}
            {power_surges && <div className={styles.symptom}>power_surges</div>}
            {rodent_activity && <div className={styles.symptom}>rodent_activity</div>}
            {wires_are_melted && <div className={styles.symptom}>wires_are_melted</div>}
          </div>
        </div>

      const otherSymptoms = <div className={styles.section}>
        <div className={styles.title}>Other Symptoms</div>
        <div className={styles.sectionBody}>{other_symptoms}</div>
      </div>

      const inverterInfo = [
        ['Inverter Manufacturer', inverter_manufacturer], 
        ['Inverter Model', model], 
        ['Inverter Serial', serial], 
        [' Date of Manufacture', date_of_manufacture]
      ].map(item => (
        <div key={item[0]} className={styles.section}>
        <div className={styles.title}>{item[0]}: </div>
        <div className={styles.sectionBody}>
          <div>{item[1]}</div>
      </div>
    </div>
      ))

      const contactInfo = <div className={styles.section}> 
          <div className={styles.title}>Customer Info</div>
          <div className={styles.sectionBody}>
            <div>{company_name}</div>
            <div>{name_first} {name_last}</div>
            <div>{email}</div>
            <div>{phone} {phone_other}</div>
          </div>
      </div>

      const repairLocation = <div className={styles.section}>
        <div className={styles.title}>Repair Location</div>
        <div className={styles.sectionBody}>
        <div>{repair_location}</div>
        </div>
      </div>

      const billingAddress = <div className={styles.section}>
        <div className={styles.title}>Billing Address</div>
        <div className={styles.sectionBody}>
          <div className={styles.address}>
          <div>{billing_address_line_1} </div>
          <div>{billing_address_line_2}</div>
          <div>{billing_address_city}, {billing_address_state} {billing_address_zip}</div>
          <div>{billing_address_country}</div>
          </div>
        </div>
      </div>

      const shippingAddress = <div className={styles.section}>
        <div className={styles.title}>Shipping Address</div>
        {shipping_same_as_billing.length>0 || (shipping_address_line_1.length === 0 && shipping_address_line_2.length === 0 && shipping_address_city.length === 0)
        ? <div className={styles.sectionBody}>Same as billing</div> 
        : <div className={styles.sectionBody}> 
            <div>{shipping_address_name}</div>
            <div>{shipping_address_line_1}</div>
            <div>{shipping_address_line_2}</div>
            <div>{shipping_address_city}, {shipping_address_state} {shipping_address_zip}</div>
            <div>{shipping_address_country}</div>
          </div>
        }
      </div>

      const date_and_time = entry_date.replace(' ', 'T').trim()
      var d1 = DateTime.fromISO(date_and_time).minus({hours: 8})
      const date = new Date(d1.ts).toLocaleString("en-US", {timeZone: "America/Los_Angeles"})

      return (
        <div key={uuidv4()} className={styles.authForm}>
          <div>
            <div className={styles.itemHeader}>{date}</div>
            {contactInfo}
            {repairLocation}
            {billingAddress}
            {shippingAddress}
          </div>
          <div>
            {inverterInfo}
            {symptoms}
            {otherSymptoms}
          </div>
        </div>
        )
      })

    const menu = this.state.showMenu ? <Menu sortBy={this.state.sortBy} toggleSorting={this.toggleSorting} toggleMenu={this.toggleMenu} /> : null
    
    return (
      <div className="App">
        <Header onClick={() => this.toggleMenu(true)} />
        {menu}
        <h1>Inverter Authorization Forms</h1>
        {/* <ListBy sortBy={this.state.sortBy} toggleSorting={this.toggleSorting} /> */}
        <div className={styles.authFormContainer}>{this.state.sortBy === 'ascending' ? forms : forms.reverse()}</div>
      </div>
    )
  }
}
