exports = module.exports = require("../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\n\n.Dashboard_itemCollection__3U5Fn {\n  display: flex;\n  align-items: center;\n  margin: 1em 0;\n  flex-direction: column;\n}\n\n.Dashboard_searchContainer__hgITO {\n  display: flex; \n  justify-content: flex-start; \n  position: relative;  \n  width: calc(92vw);\n\n}\n\n.Dashboard_searchInput__1HBVX {\n  width: 100%;\n  padding: 1em 1em 1em 3em;\n  margin: 1em;\n}\n\n.Dashboard_clearSearch__saDrN {\n  position: absolute;\n  right: 1.5em;\n  top: 1.25em;\n  cursor: pointer;\n}\n", ""]);

// Exports
exports.locals = {
	"itemCollection": "Dashboard_itemCollection__3U5Fn",
	"searchContainer": "Dashboard_searchContainer__hgITO",
	"searchInput": "Dashboard_searchInput__1HBVX",
	"clearSearch": "Dashboard_clearSearch__saDrN"
};