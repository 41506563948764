exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Header_header__3dKDk {\n  width: calc(100vw - 2em);\n  height: 3em;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 0 1em;\n\n  z-index: 5;\n}\n\n.Header_logo__wR1Yj {\n  width: 200px;\n  margin-right: 1em;\n}\n\n.Header_menuButton__1u0CQ {\n  position: fixed;\n  right: 1em;\n  top: 1em;\n}\n\n.Header_link__3TtTQ {\n  margin: 0 1em;\n  margin-top: 0.5em;\n  font-size: 0.75em;\n  text-decoration: none;\n  color: #555;\n}\n\n.Header_link__3TtTQ:hover {\n  color: #000;\n}", ""]);

// Exports
exports.locals = {
	"header": "Header_header__3dKDk",
	"logo": "Header_logo__wR1Yj",
	"menuButton": "Header_menuButton__1u0CQ",
	"link": "Header_link__3TtTQ"
};