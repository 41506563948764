import React from 'react'
// import Filter from '../components/Menu/Filter'
import ListBy from '../components/Menu/ListBy'
import styles from './Menu.module.css'
import { useAuth0 } from "../utils/react-auth0-spa";

const Menu = (props) => {
  
    const { logout } = useAuth0();
    const logoutWithRedirect = () =>
      logout({
        returnTo: window.location.origin
      });

  return (
    <div className={styles.menu}>
      {/* Menu */}
      <div style={{width: '93%', fontWeight: 700, fontSize: '2em', height: '1.5em', display: 'flex', justifyContent: 'flex-end', color: '#fff'}}>
      <div style={{cursor: 'pointer'}} onClick={() => props.toggleMenu(false)}>x</div>
      </div>
      <ListBy sortBy={props.sortBy} toggleSorting={props.toggleSorting} />
      <button style={{margin: '3em 1em 1em', padding: '1em', borderRadius: '1em', color: '#eee'}} onClick={() => logoutWithRedirect()}>Log out</button>
      </div>
        // /* <Filter employees={['Mark S', 'David J', 'Kim A', 'Mary A', 'Robert M', 'Other']} /> */
  )
}

export default Menu