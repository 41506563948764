import React from 'react';
import {  BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import './App.css';
import Home from './screens/Home'
import { useAuth0 } from "./utils/react-auth0-spa"
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import Loading from './components/Loading';
import dotenv from 'dotenv'
import AuthForms from './screens/AuthForms/AuthForms';
import Div100vh from 'react-div-100vh'
import HttpsRedirect from 'react-https-redirect';

dotenv.config()

function App() {
  const { loading } = useAuth0();
  if (loading) {
    return <Loading />;
  }
  // const app_metadata = user['https://mse-work-order.auth0.comapp_metadata']
  // const {auth_forms} = app_metadata
  return (
    <Router>
      <HttpsRedirect>
        <div id="app" className="d-flex flex-column h-100">
        <Div100vh>
          <Switch>
            <PrivateRoute path="/auth_forms" component={AuthForms} />
            <PrivateRoute path="/" component={Home} />
            <Redirect to="/" />
          </Switch>
          </Div100vh>
        </div>
      </HttpsRedirect>
    </Router>
  );
};

export default App;