exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "\n.Item_item__YNkqm {\n  width: 90vw;\n  box-shadow: 0px 3px 6px #ccc;\n  margin: 1em 0 1em 1em;\n  height: 6.5em;\n  display: flex;\n  flex-direction: column;\n}\n\n.Item_header__1alge {\ndisplay: flex;\nalign-items: center;\njustify-content: space-between;\nfont-size: 0.75em;\nbackground-color: #F4F4F4;\npadding: 0.5em;\n}\n\n.Item_employeeOrigin__37uro {\n\n}\n\n.Item_date__2BLoM {\n\n}\n\n.Item_body__2MTHl {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 20px;\n  flex: 1 1;\n  overflow: hidden;\n}\n\n.Item_workOrderNumber__23j18 {\n  font-size: 1.5em;\n  width: 100px;\n  font-weight: 700;\n  white-space: nowrap;\n}\n\n.Item_customerName__tQdNL {\n  text-align: right;\n  margin-left: 20px;\n  flex: 1 1;\n}", ""]);

// Exports
exports.locals = {
	"item": "Item_item__YNkqm",
	"header": "Item_header__1alge",
	"employeeOrigin": "Item_employeeOrigin__37uro",
	"date": "Item_date__2BLoM",
	"body": "Item_body__2MTHl",
	"workOrderNumber": "Item_workOrderNumber__23j18",
	"customerName": "Item_customerName__tQdNL"
};